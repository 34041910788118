








































import { Component, Vue, Watch } from 'vue-property-decorator'
import AuthHeader from '@/components/elements/AuthHeader.vue'
import InputField from '@/components/form/InputField.vue'
import { RESET_PASSWORD } from '@/store/actions'

@Component({
  components: { InputField, AuthHeader },
})
export default class ResetPassword extends Vue {
  passwordResetSuccess = false
  resetPasswordForm = {
    fields: {
      password: '',
      confirmPassword: '',
    },
    error: false,
    isValid() {
      return this.fields.password.length && this.fields.confirmPassword.length && (this.fields.password === this.fields.confirmPassword)
    },
  }
  submitResetPassword() {
    this.resetPasswordForm.error = false

    if (!this.resetPasswordForm.isValid()) {
      this.resetPasswordForm.error = true
      return false
    }

    return this.$store.dispatch(RESET_PASSWORD, { token: this.$route.params.token, password: this.resetPasswordForm.fields.password })
      .then(() => {
        this.passwordResetSuccess = true
      })
  }
  @Watch('resetPasswordForm.fields', { deep: true })
  onChange() {
    this.resetPasswordForm.error = false
  }
}
