<template>
  <div class="input-field">
    <label v-if="label" :for="id" :class="['input-field__label']">{{ label }}</label>
    <input
      :id="id"
      v-model="input"
      :type="type"
      :class="['input-field__text-input', {'error': error}]"
      :placeholder="placeholder"
      :spellcheck="spellcheck"
      :disabled="disabled"
      :readonly="readonly"
      autocomplete="off"
    >
  </div>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

const validTypes = ['text', 'password', 'email', 'number']

@Component({
  props: {
    value: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    spellcheck: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: false,
      type: String,
      default: 'text',
      validator: (propValue) => {
        return validTypes.includes(propValue)
      },
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
})
export default class InputField extends Vue {
  id = Math.random().toString(36)
  input = ''
  created() {
    this.input = this.value
  }
  @Watch('input')
  onChange(newValue) {
    this.$emit('update:value', newValue)
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/functions';

.input-field {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: bold;
    color:#676767;
    margin-bottom: rem(15);
  }

  &__text-input {
    border-radius: rem(24);
    background-color: #FFFFFF;
    box-shadow: inset 0 rem(1) rem(5) 0 rgba(0,0,0,0.1);
    padding: rem(12);
    color: #676767;
    font-size:rem(14);
    text-align: center;


    &::placeholder {
      color: #70909C;
      opacity: 0.5;
      font-weight: bold;
    }

    &.error {
      border:rem(2) solid red;
    }
  }

}
</style>
